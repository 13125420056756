import 'stylesheets/home'
import './home/banner_sliders'

const Home = {
  clickLikeQuestion: function(selectorContainer, selectorButton) {
    if (!(userSignInState && $(selectorContainer).length)) return;

    const button = $(`${selectorContainer} ${selectorButton}`);

    button.click(function(event) {
      const leftClicked = (event.which === 1);

      leftClicked && $(this).addClass('loading');
    });
  },
  events: function() {
    Home.clickLikeQuestion('#home .question', '.like a');
  }
};

$(document).on('turbolinks:load', function() {
  Home.events();
});
