import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

const HomeBannerSliders = {
  init: function(settings) {
    HomeBannerSliders.timeoutId = settings.timeoutId
    HomeBannerSliders.windowWidth = settings.windowWidth
  },
  touchEvents: function(selector) {
    $(selector).on('touchmove', function(e) {
      e.preventDefault()
    })
  },
  initializeSlider: function(container, slider) {
    $(slider).on('initialized.owl.carousel', function() {
      $(container).removeClass('loading')
    })
  },
  toggleItemsLength: function(itemsLength, maximumLength) {
    return itemsLength < maximumLength ? itemsLength : maximumLength
  },
  toggleItemsState: function(itemsLength, maximumLength) {
    return itemsLength >= maximumLength
  },
  configSlider: function(selectorContainer, selectorSlider, imgLength) {
    if ($(selectorContainer).length && $(selectorSlider).length) {
      HomeBannerSliders.initializeSlider(selectorContainer, selectorSlider)

      const
        itemsStateMobile = HomeBannerSliders.toggleItemsState(imgLength, 1.5),
        itemsStateDesktop = HomeBannerSliders.toggleItemsState(imgLength, 2.5),
        itemsLengthMobile = HomeBannerSliders.toggleItemsLength(imgLength, 1.5),
        itemsLengthDesktop = HomeBannerSliders.toggleItemsLength(imgLength, 2.5)

      setTimeout(function() {
        $(selectorSlider).owlCarousel({
          autoplay: true,
          autoplayHoverPause: true,
          autoPlay: 3000,
          dotsEach: true,
          items: itemsLengthMobile,
          loop: itemsStateMobile,
          nav: false,
          margin: 10,
          slideBy: 1,
          stagePadding: 10,
          smartSpeed: 400,
          responsive: {
            768: {
              items: itemsLengthMobile,
              loop: itemsStateMobile
            },
            1025: {
              items: itemsLengthDesktop,
              loop: itemsStateDesktop
            }
          }
        })
      }, 300)

      HomeBannerSliders.touchEvents(selectorSlider)
    }
  },
  resizeSlider: function(selector) {
    if ($(selector).length && HomeBannerSliders.windowWidth !== $(window).width()) {
      HomeBannerSliders.blurSlider(selector, true)
      clearTimeout(HomeBannerSliders.timeoutId)
      HomeBannerSliders.timeoutId = setTimeout(function() {
        HomeBannerSliders.blurSlider(selector, false)
      }, 300)
      HomeBannerSliders.windowWidth = $(window).width()
    }
  },
  blurSlider: function(selector, state) {
    $(selector).toggleClass('resizing', state)
  },
  loads: function() {
    HomeBannerSliders.configSlider('#banner-sliders .list', '#banner-sliders .owl-carousel', homeBannerSlideLength)
  },
  resizingEvents: function() {
    HomeBannerSliders.resizeSlider('#banner-sliders .list .owl-carousel')
  }
}

HomeBannerSliders.init({
  timeoutId: null,
  windowWidth: $(window).width(),
})

$(document).on('turbolinks:load', function() {
  HomeBannerSliders.loads()
})

$(window).resize(function() {
  HomeBannerSliders.resizingEvents()
})
